import * as React from 'react'
import { Helmet } from 'react-helmet'
import LayoutPrimary from '../templates/LayoutPrimary'
import { StaticImage } from 'gatsby-plugin-image'
import { imgBox } from '../styles/components/boxes.module.scss'
import { bannerBlock, bannerBlockHeadline, bannerBlockTagline, bannerBlockTitleSm, careersBlock } from '../styles/components/blocks.module.scss'
import { formContainer, formHeadline, formWrapper, formItem } from '../styles/components/forms.module.scss'
import { btn } from '../styles/components/buttons.module.scss'
import { h1, sub } from '../styles/components/titles.module.scss'
import { hideBlockSM } from '../styles/components/helpers.module.scss'
import { list2 } from '../styles/components/lists.module.scss'

const CareersPage = () => {
  return (
    <LayoutPrimary>
      <Helmet>
        <title>Party Boat Careers in Tampa Bay, FL | Tampa Bay Boating Adventures</title>
        <meta name="description" content="Professional Captain? Bottlegirl? DJ? We’re looking for the BEST! Apply online today." />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <header className='container'>
        <div className='row'>
          <div className='col-xxs-12'>
            <div className={bannerBlock}>
              <div className={imgBox}>
                <StaticImage
                  alt="TBBA owners pointing at their party boat logo in Tampa Bay, FL."
                  src="../images/banners/tbba-owners-pointing-at-party-boat-logo.png"
                />
              </div>
              <div className={bannerBlockHeadline}>
                <h1 className={bannerBlockTagline}>Party Boat Careers in Tampa Bay, FL</h1>
                <span className={bannerBlockTitleSm}>Work with Our Party Boat Team</span>
              </div>
            </div>
          </div>
        </div>
      </header>
      <main className='container'>
        <div className='row'>
          <div className='col-xxs-12'>
            <div className={careersBlock}>
              <div className='row middle'>
              <div className='col-md-7 col-xxs-12'>
                <div className='this-pad-all-4-lg this-pad-all-2-sm text-left-sm text-center'>
                  <h2 className={h1}>Think You Have <br className={hideBlockSM}/>What It Takes?</h2>
                  <p>We're always looking for top-tier captains, professional photographers, and model/bottlegirls!</p>
                  <ul className={list2}>
                    <li>Fun Atmosphere</li>
                    <li>Exciting Work Environment</li>
                    <li>Work Your Hours</li>
                    <li>Advancement Opportunity</li>
                  </ul>
                  <p>If you want to join the hottest party boat operation in the Tampa Bay, Florida area, Tampa Bay Boating Adventures is the company you want to work for! Apply online today for consideration and start you career in paradise!</p>
                </div>
              </div>
              <div className='col-md-5 col-xxs-12 first'>
                <div className={formContainer}>
                  <div className={formHeadline}>
                    <div className={h1}><span className={sub}>Apply Online to</span> Work with the TBBA Team</div>
                  </div>
                  <form action="https://getform.io/f/5a60d2e1-2d42-4267-95e2-11b6b8e6738f" method="POST" className="tbbaForm">
                    <div className={formWrapper}>
                      <div className={formItem}>
                        <label for="tbbaFormName">What's your name?</label>
                        <input id="tbbaFormName" type="text" name="name" placeholder="What's your name?" />
                      </div>
                      <div className={formItem}>
                        <label for="tbbaFormInstagram">What's your Instagram?</label>
                        <input id="tbbaFormInstagram" type="text" name="email" placeholder="What's your Instagram?" />
                      </div>
                      <div className={formItem}>
                        <label for="tbbaFormCareerChoice">Choose a Career</label>
                        <select id="tbbaFormCareerChoice">
                          <option selected>Choose a Career</option>
                          <option>Party Boat Captain</option>
                          <option>Party Boat Photographer</option>
                          <option>Party Boat Model or Bottlegirl</option>
                        </select>
                      </div>
                      <div className={formItem}>
                        <label for="tbbaFormMessage">How could you help TBBA go next level?</label>
                        <textarea id="tbbaFormMessage" type="text" name="message" placeholder="How could you help TBBA go next level?" />
                      </div>
                      <div className={formItem}>
                        <button type="submit" className={btn}>Send Application!</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </LayoutPrimary>
  )
}

export default CareersPage